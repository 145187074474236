import React from "react"

import { Container } from "react-bootstrap"

const WhatIsMicroIncome = () => (
  <section id="what-is-micro-income" className="section-what-is-micro-income">
    <Container>
      <h3>
        <span>The Dawn of</span> Micro Income!
      </h3>
      <p>
        Until now, micro income was only a dream. People have always wanted
        income from sources in the background of life, but it was a problem
        without a solution. There was no way for companies to service micro
        accounts or make micro payments without losing money. Fortunately,
        advances in technology and computing power over the decades have brought
        down the cost, slowly making the micro income dream a reality.
      </p>
      <p>
        Who would have guessed that the pagers/beepers of the early 60’s would
        evolve into cell phones which would become smartphones - and much more
        than just communications devices? Today with an app for just about
        everything imaginable, smartphones are the de-facto platform for micro
        income generation and distribution. And, we are now seeing the birth of
        multiple companies that generate micro income for the consumer.
      </p>
    </Container>
  </section>
)

export default WhatIsMicroIncome
