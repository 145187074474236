import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import HeaderIndex from "../components/section-header-index"
import BackgroundTopology from "../components/background/topology"
import WhatIsMicroIncome from "../components/section-what-is-micro-income"
import Newsletter from "../components/section-newsletter"
import ContactUs from "../components/section-contact-us"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Top 15 Micro Income Apps" />
    <HeaderIndex />
    <BackgroundTopology>
      <WhatIsMicroIncome />
      <Newsletter />
      <ContactUs />
    </BackgroundTopology>
  </Layout>
)

export default IndexPage
