import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container, CardDeck } from "react-bootstrap"

import BackgroundLaser from "../background/laser"
import CardApp from "../card-app"
import ButtonWhiteOutline from "../button/white-outline"

const HeaderIndex = () => {
  const { allAppsJson } = useStaticQuery(graphql`
    query {
      allAppsJson(limit: 3, sort: { order: ASC, fields: order }) {
        edges {
          node {
            id
            path
            title
            description
            featured_image {
              childImageSharp {
                fluid(maxWidth: 320, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            link_ios
            link_android
            link_website
            link_affiliate_app
            link_affiliate_web
            platform
          }
        }
      }
    }
  `)

  return (
    <BackgroundLaser className="section-header-index">
      <Container>
        <h1>
          <span>Top 15</span> Micro Income Apps!
        </h1>
        <h2>
          You can earn an extra $50 to $500 a month with one or more of the
          following micro income apps.
        </h2>
        <CardDeck className="card-app-deck">
          {allAppsJson.edges.map((app) => {
            const {
              id,
              path,
              title,
              description,
              featured_image,
              alt,
              link_ios,
              link_android,
              link_affiliate_app,
              link_affiliate_web,
              link_website,
              platform,
            } = app.node
            return (
              <CardApp
                key={id}
                title={title}
                description={description}
                image={featured_image.childImageSharp.fluid}
                alt={alt}
                path={path}
                ios={link_ios}
                android={link_android}
                website={link_website}
                affiliate_app={link_affiliate_app}
                affiliate_web={link_affiliate_web}
                platform={platform}
              />
            )
          })}
        </CardDeck>
        <ButtonWhiteOutline as={Link} to="/top-15-apps">
          See More Apps
        </ButtonWhiteOutline>
      </Container>
    </BackgroundLaser>
  )
}

export default HeaderIndex
