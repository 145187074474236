import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"

const BackgroundLaser = ({ className, children, ...props }) => {
  const { mobileImage, desktopImage } = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "Lasermdpi@3x.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 767) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(relativePath: { eq: "Image_Float@3x.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <BackgroundImage
      id="background-laser"
      Tag="section"
      className={className}
      fluid={sources}
      {...props}
    >
      {children}
    </BackgroundImage>
  )
}

BackgroundLaser.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default BackgroundLaser
