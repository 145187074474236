import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"

const BackgroundTopology = ({ className, children, ...props }) => {
  const { topologyImage } = useStaticQuery(graphql`
    query {
      topologyImage: file(relativePath: { eq: "BG@3x.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    `linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 1) 100%)`,
    topologyImage.childImageSharp.fluid,
  ]

  return (
    <BackgroundImage
      id="background-topology"
      className={className}
      fluid={sources}
      {...props}
    >
      {children}
    </BackgroundImage>
  )
}

BackgroundTopology.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default BackgroundTopology
