import React from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"

const ButtonWhiteOutline = ({ children, ...props }) => (
  <div className="button-white-outline">
    <Button {...props} variant="outline-light">
      {children}
    </Button>
  </div>
)

ButtonWhiteOutline.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ButtonWhiteOutline
